
import icon1 from '../../assets/img/areas/1.webp';
import icon2 from '../../assets/img/areas/2.webp';
import icon3 from '../../assets/img/areas/3.webp';
import icon4 from '../../assets/img/areas/4.webp';
import icon5 from '../../assets/img/areas/5.webp';
import icon6 from '../../assets/img/areas/6.webp';



function ApplicationArea() {
    return (

        <section className="applicationSec bg_light_grey" id='applicationareas'>
            <div className="container">
              <div className="sectionTitle">
                <h6>PRODUCTS & APPLICATION AREAS</h6>
                <h1>Our application areas</h1>
                <p>We offer our services in diverse areas <br/>
                 We offer superior quality, best price and fastest delivery in the manufacturing of pre-fab structures, large storage tanks, large storage bins etc.</p>
              </div>

              <div className="applicationRow">
                <div className="applicationCard">
                  <img src={icon1} alt='icon1' />
                  <h5>Construction</h5>
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing.</p> */}
                </div>

                <div className="applicationCard">
                  <img src={icon2} alt='icon1' />
                  <h5>Energy</h5>
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing.</p> */}
                </div>

                <div className="applicationCard">
                  <img src={icon3} alt='icon1' />
                  <h5>Transmission</h5>
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing.</p> */}
                </div>

                <div className="applicationCard">
                  <img src={icon4} alt='icon4' />
                  <h5>Telecom</h5>
                </div>

                <div className="applicationCard">
                  <img src={icon5} alt='icon5' />
                  <h5>F&B</h5>
                </div>

                <div className="applicationCard">
                  <img src={icon6} alt='icon6' />
                  <h5>Chemical Packaging & Many more</h5>
                </div>

            
              </div>
              
            </div>
        </section>



    )
}

export default ApplicationArea