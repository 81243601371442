export class AuthService {
    
    logout() {
        localStorage.removeItem('email');
        localStorage.removeItem('token');
        localStorage.removeItem('userType');
        localStorage.removeItem('userId');
        localStorage.removeItem('userName');
        localStorage.removeItem('session');
        localStorage.removeItem('key');
    }

    getToken(){
        localStorage.getItem('session')
    }
}