function VendorLogin() {

  const urlMapping: any = {
    "fb.arachnomesh.com": "https://vendor.arachnomesh.com",
    "fb-demo.arachnomesh.com": "https://fb-vendor.arachnomesh.com",
    "fabricationbazar.com": "https://vendor.fabricationbazar.com",
  }

  
    return (

        <div className="vendor-login-sec">
            <div className="container">
              <div className="vendor-login-row">

             
              <div className="sectionTitle">
                <h6>ARE YOU A VENDOR ?</h6>
                <h1>For vendors</h1>
              </div>

               <div>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href={urlMapping[window.location.host]} target='_blank' className="btn brownBtn nav-link">Login as vendor</a>
                </div> 
                </div>
                
               
              
            </div>
        </div>



    )
}

export default VendorLogin