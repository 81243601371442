import React from 'react';
import arrow from "../../assets/img/arrow_right.png";

function Breadcrumb() {
    return (
        <div className='breadCrumb'>
            <h1 className='text-white'>All Products</h1>
            <ul><li>Home</li>
                <li><img src={arrow} alt='arrow' /></li>
                <li>Products</li>
                <li><img src={arrow} alt='arrow' /></li>
                <li>All Products</li>
            </ul>

        </div>
    )
}

export default Breadcrumb;