import productApi from "../../apis/productApi";
import { ActionTypes } from "../constants/action-types";

//action will make the request t0 get the otp
export const sendQuery = (query: any) => async (dispatch: any) => {
    try {
        const response = await productApi.post("/query", query);
        dispatch({
            type: ActionTypes.ADD_QUERY,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: ActionTypes.QUERY_FAIL,
            payload: error,
        });

    }
}

// UPLOAD_FILE: "UPLOAD_FILE",
//     UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
//     UPLOAD_FILE_FAIL: "UPLOAD_FILE_FAIL",

export const uploadFile = (data: any) => async (dispatch: any) => {
    try {
        const response = await productApi.post("file/upload", data);
        dispatch({
            type: ActionTypes.UPLOAD_FILE,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: ActionTypes.UPLOAD_FILE_FAIL,
            payload: error,
        });

    }
}

export const clearEnquiryId = () => async (dispatch: any) => {
    console.log("action called")

    dispatch({
        type: ActionTypes.CLEAR_ENQUIRY_ID,
        payload: {},
    });


}





