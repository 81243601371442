/* eslint-disable */
// import img from "../../assets/img/img1.webp";
// import { Button } from "react-bootstrap";
import "swiper/css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
// import QuoteButton from "../Misc/QuoteButton";
// import ProductCard from "../ProductDetail/ProductCard";
import { useEffect } from "react";
// import SingleProductCard from "../ProductDetail/SingleProductCard";

import { fetchProducts } from "../../redux/actions/productActions";
import { useAppDispatch, useAppSelector } from "../../redux/hook"
// import { useNavigate } from "react-router-dom"
// import star1 from "../../assets/img/starYellow.png";
// import star2 from "../../assets/img/starGrey.png";
// import { Col } from 'react-bootstrap';
import ProductSliderCard from "../ProductDetail/ProductSliderCard";

function ProductSec() {

  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProducts(""))
  }, [])

  const products = useAppSelector((state: any) => state.allProducts.products);
  // console.log(products, " in product section")

  return (
    <section className="prodctSec">
      <div className="container">
        <div className="sectionTitle">
          <h6>PRODUCTS & APPLICATION AREAS</h6>
          <h1 className="text-black">Our best in class products</h1>
          <p>We offer 50+ different set of designed products and 20+ designed verticals in a variety of range.</p>
        </div>

        <div className="productSliderRow relatedProduct">
          <Swiper
            effect="slide"
            autoplay={{
              delay: 2500,
            }}
            initialSlide={1}
            loop={true}
            slidesPerView={1}
            navigation={true}
            pagination={false}
            modules={[Navigation, Autoplay]}
            // Responsive breakpoints
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              // when window width is >= 767px
              767: {
                spaceBetween: 20,
                slidesPerView: 2,
              },

              // when window width is >= 959px
              959: {
                spaceBetween: 50,
                slidesPerView: 3,
              },
            }}
          >


            {/* <SwiperSlide >
              <SingleProductCard {name:name id:id description:description} />
            </SwiperSlide> */}

            {products?.map((item: any) => {
              return (<SwiperSlide >
                <ProductSliderCard item={item} id={item.id - 1} />
              </SwiperSlide>)
            })}
            {/* <SwiperSlide >
              <ProductCard /> */}
            {/* <div className="productCard">
                <img src={img} alt="img" />
                <div className="details">
                  <div className="detailsHeading">
                    <h5>Bolted Water Tanks</h5>

                  </div>

                  <div className="contentDiv">
                    <div className="contnt">
                      <div className="description">
                        <h5>PRODUCT DESCRIPTION</h5>
                        <p>
                          Bolted Panel Zn-Alume tanks, MS Tanks, SS Tanks etc.
                          from 2 KL to 10000 KL as per IS, ANSI, DIN for raw and
                          fire water, Molasses, DM water, Fuel, Consumable
                          Liquid etc.
                        </p>
                      </div>

                      <h6>GENERAL PARAMETERS</h6>

                      <p>

                        <span> CAPACITY: </span> 2 KL to 10000 KL
                      </p>
                      <p>

                        <span>MATERIAL: </span> ZN ALUME, MS, SS
                      </p>
                    </div>
                    <div className="btnDiv">

                      <Button className="brownBtn" variant="primary">
                        Request for Quote
                      </Button>
                    </div>
                  </div>
                </div>
              </div> */}
            {/* </SwiperSlide> */}

          </Swiper>
        </div>

        <div className="text-end w-100">
          <a href="/productDetail" className='linkBtn'>View all products</a>
        </div>

      </div>
    </section>
  );
}

export default ProductSec;
