import React from 'react';
import thumbnail from "../../assets/img/videoimg.webp";
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import close from "../../assets/img/close.png";

import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hook';

function MyVerticallyCenteredModal(props: any) {



  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='videoModal'
    >


      <Modal.Body className='p-0'>
        {/* <iframe width="100%" height="450" src="https://www.youtube.com/embed/U_J8arriAj0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}

        <video autoPlay={true} playsInline muted controls loop width='100%' height='100%'>
                        <source type="video/mp4" src={require('../../assets/img/videos/first.mp4')}></source>
       </video>


      </Modal.Body>


      <Button className='brownBtn' onClick={props.onHide}>  <img src={close} alt='cross' /> </Button>

    </Modal>
  );
}


function VideoSec() {

  const [modalShow, setModalShow] = React.useState(false);
  const { id } = useParams();
  console.log(id);

  const singleData = useAppSelector((state: any) => state.product.singleProductData);
  console.log(singleData);



  return (
    <div>

      <div className='videoSec'>
        <img src={thumbnail} className='w-100' alt='videothumnail' />
        <div className='btnOuter'>
          <button className="btn-play" onClick={() => setModalShow(true)}></button>
          <h3 className='text-white mt-3'>{singleData?.name}</h3>
        </div>
      </div>

      <MyVerticallyCenteredModal show={modalShow}
        onHide={() => setModalShow(false)} />

    </div>

  )
}


export default VideoSec; 