import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux'
import reducers from './reducers/index'
import thunk from 'redux-thunk'
declare global {

    interface Window {

        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;

    }

}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export default store