/* eslint-disable */
import  { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../Footer';
import Header from '../Header';


import "swiper/css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Navigation, Autoplay } from 'swiper';
import Banner from './Banner';
import About from './AboutProduct';
import VideoSec from './videoSec';
import FeaturesSec from './features';
import { useParams } from 'react-router-dom';
import { fetchProducts } from "../../redux/actions/productActions";
import { useAppDispatch, useAppSelector } from "../../redux/hook"
import ProductSliderCard from '../ProductDetail/ProductSliderCard';

function SingleDetail() {
  // const [pId, setPid] = useState<any>()
  const products = useAppSelector((state: any) => state.allProducts.products);
  // const singleData = useAppSelector((state: any) => state.product.singleProductData);
  const dispatch = useAppDispatch();
  // const { id } = useParams()

  useEffect(() => {
    dispatch(fetchProducts(""))
  }, [])

  // window.scrollTo(0, 0);

  // useEffect(() => {
  //   dispatch(fetchSingleProducts(id))
  // }, [id])

  // useEffect(() => {
  //   if (singleData) {
  //   }
  // }, [singleData])

  return (
    <div className='singleDetailSec'>
      <Header />
      <div className='singleProduct'>
        <Banner />
        <Container>
          <About />

          <div className='relatedProduct'>
            <h4 className='mb-3'>RELATED PRODUCTS</h4>
            <Swiper
              effect='slide'
              autoplay={{
                delay: 2500
              }}
              initialSlide={1}
              loop={true}
              slidesPerView={3}
              navigation={true}
              modules={[Navigation, Autoplay]}
              spaceBetween={50}
              // Responsive breakpoints
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 2,
                  spaceBetween: 10
                },
                // when window width is >= 767px
                767: {
                  slidesPerView: 2,
                  spaceBetween: 40
                },

                // when window width is >= 959px
                959: {
                  slidesPerView: 3,
                  spaceBetween: 50
                }
              }}
            >
              {products?.map((item: any) => {
                // setPid(item.id)
                return (<SwiperSlide >
                  {/* <h1>{item.id}</h1> */}
                  <ProductSliderCard item={item} id={item.id - 1} />
                </SwiperSlide>)
              })}
            </Swiper>
          </div>

        </Container>
        <VideoSec />
        <Container>
          <FeaturesSec />
        </Container>
      </div>
      <Footer />
    </div>
  )
}

export default SingleDetail