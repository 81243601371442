import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import "swiper/css";
// // Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css/navigation";



import client1 from '../../assets/img/clients/client1.png';
import client2 from '../../assets/img/clients/client2.png';
import client3 from '../../assets/img/clients/client3.png';
import client4 from '../../assets/img/clients/client4.png';


import cement1 from '../../assets/img/clients/cement1.png';
import cement2 from '../../assets/img/clients/cement2.png';
import cement3 from '../../assets/img/clients/cement3.png';
import cement4 from '../../assets/img/clients/cement4.png';

import fmcg1 from '../../assets/img/clients/fmcg1.png';
import fmcg2 from '../../assets/img/clients/fmcg2.png';
import fmcg3 from '../../assets/img/clients/fmcg3.png';
import fmcg4 from '../../assets/img/clients/fmcg4.png';

import energy1 from '../../assets/img/clients/energy1.png';
import energy2 from '../../assets/img/clients/energy2.png';
import energy3 from '../../assets/img/clients/energy3.png';
import energy4 from '../../assets/img/clients/energy4.png';

import logistics1 from '../../assets/img/clients/logistics1.png';
import logistics2 from '../../assets/img/clients/logistics2.png';
import logistics3 from '../../assets/img/clients/logistics3.png';
import logistics4 from '../../assets/img/clients/logistics4.png';

import manu1 from '../../assets/img/clients/manu1.png';
import manu2 from '../../assets/img/clients/manu2.png';
import manu3 from '../../assets/img/clients/manu3.png';
import manu4 from '../../assets/img/clients/manu4.png';
import manu5 from '../../assets/img/clients/manu5.png';


import oil1 from '../../assets/img/clients/oil1.png';
import oil2 from '../../assets/img/clients/oil2.png';
import packaging1 from '../../assets/img/clients/packaging1.png';
import packaging2 from '../../assets/img/clients/packaging2.png';
import packaging3 from '../../assets/img/clients/packaging3.png';

import paint1 from '../../assets/img/clients/paint1.png';
import paint2 from '../../assets/img/clients/paint2.png';
import rail1 from '../../assets/img/clients/rail1.png';
import rail2 from '../../assets/img/clients/rail2.png';

import spirits1 from '../../assets/img/clients/spirits1.png';
import spirits2 from '../../assets/img/clients/spirits2.png';
import spirits3 from '../../assets/img/clients/spirits3.png';
import spirits4 from '../../assets/img/clients/spirits4.png';
import spirits5 from '../../assets/img/clients/spirits5.png';

import tyre1 from '../../assets/img/clients/tyre1.png';
import tyre2 from '../../assets/img/clients/tyre2.png';
import tyre3 from '../../assets/img/clients/tyre3.png';
import tyre4 from '../../assets/img/clients/tyre4.png';


function ClientSec() {
    return (

        <section className="clientsSec">
            <div className="container">
              

              <div className="sectionTitle">
                <h6>OUR TRUSTED CLIENTS</h6>
                <h1>Some of our top partner companies</h1>
              </div>

              
                <div className="counter">
                  <ul>
                    <li>
                      <h2>
                        350 <span>+</span>
                      </h2>
                      <p>Clients</p>
                    </li>

                    <li>
                      <h2>
                        20 <span>+</span>
                      </h2>
                      <p>Industry Verticals</p>
                    </li>

                    <li>
                      <h2>
                        50% <span>+</span>
                      </h2>
                      <p>Clients through Reference & Retention</p>
                    </li>

                    <li>
                      <h2>
                        10 <span>+</span>
                      </h2>
                      <p>Countries</p>
                    </li>
                  </ul>
                </div>


                
              <Tabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="all" title="All">
                 
                <Swiper
                  effect="slide"
                  autoplay={{
                    delay: 2500,
                  }}
                  initialSlide={1}
                  loop={true}
                  slidesPerView={5}
                  navigation={true}
                  pagination={false}
                  modules={[Navigation, Autoplay]}
                  // Responsive breakpoints
                  breakpoints={{
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    // when window width is >= 767px
                    767: {
                      spaceBetween: 10,
                      slidesPerView: 5,
                    },

                    // when window width is >= 959px
                    959: {
                      spaceBetween: 10,
                      slidesPerView: 5,
                    },
                  }}
                >
                <SwiperSlide>
                    <div className="clients">
                      <img src={client1} alt='logo'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                   <div className="clients">
                      <img src={client2} alt='logo'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={client3} alt='logo'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={client4} alt='logo'/>
                    </div>
                </SwiperSlide>


                <SwiperSlide>
                    <div className="clients">
                      <img src={fmcg1} alt='logo'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                   <div className="clients">
                      <img src={fmcg2} alt='logo'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={fmcg3} alt='logo'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={fmcg4} alt='client'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={oil1} alt='clientImg'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={oil2} alt='clientImg'/>
                    </div>
                  
                </SwiperSlide>


                <SwiperSlide>
                    <div className="clients">
                      <img src={energy1} alt='logo'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                   <div className="clients">
                      <img src={energy2} alt='logo'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={energy3} alt='logo'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={energy4} alt='client'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                      <img src={tyre1} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                   <div className="clients">
                      <img src={tyre2} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={tyre3} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={tyre4} alt='client'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                      <img src={logistics1} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                   <div className="clients">
                      <img src={logistics2} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={logistics3} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={logistics4} alt='client'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={paint1} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={paint2} alt='client'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                      <img src={manu1} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                   <div className="clients">
                      <img src={manu2} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={manu3} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={manu4} alt='client'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="clients">
                        <img src={manu5} alt='client'/>
                    </div>
                </SwiperSlide>



                <SwiperSlide>
                    <div className="clients">
                      <img src={spirits1} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                   <div className="clients">
                      <img src={spirits2} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={spirits3} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={spirits4} alt='client'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="clients">
                        <img src={spirits5} alt='client'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={rail1} alt='client'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="clients">
                        <img src={rail2} alt='client'/>
                    </div>
                </SwiperSlide>


                <SwiperSlide>
                    <div className="clients">
                      <img src={cement1} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                   <div className="clients">
                      <img src={cement2} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={cement3} alt='teammember'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={cement4} alt='client'/>
                    </div>
                </SwiperSlide>


                <SwiperSlide>
                   <div className="clients">
                      <img src={packaging2} alt='logo'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={packaging3} alt='logo'/>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="clients">
                        <img src={packaging1} alt='client'/>
                    </div>
                </SwiperSlide>




                </Swiper>



                </Tab>
                <Tab eventKey="epc" title="EPC">
                    
                <div className="clients">
                  <img src={client1} alt='clientImg'/>
                  <img src={client2} alt='clientImg'/>
                  <img src={client3} alt='clientImg'/>
                  <img src={client4} alt='clientImg'/>
                
                </div>
                 
                </Tab>
                <Tab eventKey="fmcg" title="FMCG">
                      
                  <div className="clients">
                    <img src={fmcg1} alt='clientImg'/>
                    <img src={fmcg2} alt='clientImg'/>
                    <img src={fmcg3} alt='clientImg'/>
                    <img src={fmcg4} alt='clientImg'/>
                    
                  </div>
                </Tab>
                <Tab eventKey="oil" title="Oil & Gas">
                    
                  <div className="clients">
                    <img src={oil1} alt='clientImg'/>
                    <img src={oil2} alt='clientImg'/>
                  </div>
               
                </Tab>

                <Tab eventKey="energy" title="Energy">
                    
                    <div className="clients">
                      <img src={energy1} alt='clientImg'/>
                      <img src={energy2} alt='clientImg'/>
                      <img src={energy3} alt='clientImg'/>
                      <img src={energy4} alt='clientImg'/>
                     
                    </div>
                 
                  </Tab>

                  <Tab eventKey="tyre" title="Tyre">
                    
                    <div className="clients">
                      <img src={tyre1} alt='clientImg'/>
                      <img src={tyre2} alt='clientImg'/>
                      <img src={tyre3} alt='clientImg'/>
                      <img src={tyre4} alt='clientImg'/>
                     
                    </div>
                 
                  </Tab>

                  <Tab eventKey="paints" title="Paints & Chemicals">
                    
                    <div className="clients">
                      <img src={paint1} alt='clientImg'/>
                      <img src={paint2} alt='clientImg'/>
          
                    </div>
                 
                  </Tab>

                  <Tab eventKey="logistics" title="Logistics Park">
                    
                    <div className="clients">
                      <img src={logistics1} alt='clientImg'/>
                      <img src={logistics2} alt='clientImg'/>
                      <img src={logistics3} alt='clientImg'/>
                      <img src={logistics4} alt='clientImg'/>                 
                    </div>
                 
                  </Tab>

                  <Tab eventKey="manufacturing" title="Manufacturing">
                    
                    <div className="clients">
                      <img src={manu1} alt='clientImg'/>
                      <img src={manu2} alt='clientImg'/>
                      <img src={manu3} alt='clientImg'/>
                      <img src={manu4} alt='clientImg'/>
                      <img src={manu5} alt='clientImg'/>
                     
                    </div>
                 
                  </Tab>

                  <Tab eventKey="infra" title="Railways / Infra">
                    
                    <div className="clients">
                      <img src={rail1} alt='clientImg'/>
                      <img src={rail2} alt='clientImg'/>
                     
                    </div>
                 
                  </Tab>

                  <Tab eventKey="spirits" title="Spirits">
                    
                    <div className="clients">
                      <img src={spirits1} alt='clientImg'/>
                      <img src={spirits2} alt='clientImg'/>
                      <img src={spirits3} alt='clientImg'/>
                      <img src={spirits4} alt='clientImg'/>
                      <img src={spirits5} alt='clientImg'/>
                     
                    </div>
                 
                  </Tab>

                  <Tab eventKey="cement" title="Cement">
                    
                    <div className="clients">
                      <img src={cement1} alt='clientImg'/>
                      <img src={cement2} alt='clientImg'/>
                      <img src={cement3} alt='clientImg'/>
                      <img src={cement4} alt='clientImg'/>
                     
                    </div>
                 
                  </Tab>

                  <Tab eventKey="textiles" title="Packaging & Textiles">
                    
                    <div className="clients">
                      <img src={packaging1} alt='clientImg'/>
                      <img src={packaging2} alt='clientImg'/>
                      <img src={packaging3} alt='clientImg'/>
                     
                    </div>
                 
                  </Tab>

                
              </Tabs>


             

              
              </div>


           
        </section>



    )
}

export default ClientSec