import Container from "react-bootstrap/Container";
import Header from "../Header";
import Footer from "../Footer";

function TermsPage() {
  return (
    <div className="TermsPage">
      <Header />
      <Container>
        <section className="contentPage">
          <h1 className="text-brown mb-3">Terms and Conditions</h1>

          <h4>EXCLUSIONS:</h4>

          <p>
            Civil foundation work, civil foundation structural calculations and
            related warranty, office, buildings, road or work clearance.{" "}
          </p>
          <p>
            Supply of water, electricity, grain etc for the installation and
            commissioning purpose. Any item which is not mentioned in the agreed
            scope of supply and not mentioned in the commercial offer. Plant
            hire equipment; personnel lifts, forklifts, cranes and any other
            equipment like scaffolding, generators and site lighting
            arrangement. Material unloading at site, material safe movement
            inside site premises, safe storage of material at site. Any
            alterations or additions that may be necessary to ether new or
            existing buildings or installations.{" "}
          </p>
          <p>
            {" "}
            Any defect or fault arising in the installation, commissioning or
            functioning of the tanks, due to any of the exclusions listed above
            or any work in the scope of the Buyer, will not be attributable to
            the Seller and the repairs shall be done at the cost and risk of the
            Buyer.
          </p>

          <p>
            {" "}
            <strong>General : </strong> Capitalized terms are defined below.
            Unless other terms are specified in Seller’s Quotation or Invoice,
            the following Terms and Conditions (“Terms”) will govern the offer
            and sale of all Goods and Services provided by Seller to Buyer. Any
            terms and conditions of sale (other than the economic terms of the
            Order) that may be contained in any purchase order or other form
            provided by Buyer will be without force and effect, regardless of
            when received by Seller. By placing an Order, Buyer accepts these
            Terms, and the sale and delivery by Seller of Goods and Services
            will be conclusively presumed to be subject to these Terms.
          </p>

          <p>
            {" "}
            <strong>Definitions: </strong> “Buyer” and “Seller” are identified
            on the Quotation or Invoice. “Goods” are all equipment, products,
            goods, or materials listed on a Quotation or Invoice. “Services” are
            services listed on a Quotation or Invoice. A “Quotation” is issued
            by Seller to Buyer to offer the sale of Goods and any Services and
            is valid for a period of 30 days from the date of issue. “Order”
            means Buyer’s commitment to purchase Goods from Seller. A “Proforma
            Invoice” is a written request for advance payment for Goods and/or
            Services provided by Seller to Buyer for Orders accepted by Seller
            in writing or by performance.{" "}
          </p>

          <p>
            {" "}
            <strong> Order: </strong>Seller will have the right to accept or
            reject an Order in its sole discretion. Buyer will not have the
            right to cancel an Order without Seller’s prior written consent.{" "}
          </p>

          <p>
            {" "}
            <strong> Purchase Price and Taxes:</strong> Purchase Price will be
            set forth in the Quotation. Unless specifically stated, Purchase
            Price shall not include sales, consumption, use, excise or other
            similar taxes or duties (“Taxes”). Buyer will pay any and all
            clearance charges, insurance fees, transit fees, Taxes and other
            amounts payable in connection with Seller’s delivery of Goods and
            provision of Services (“Transit Charges”). If Seller is required to
            pay any Taxes on the Goods or Services furnished hereunder, Buyer
            will promptly reimburse Seller for such Taxes.{" "}
          </p>

          <p>
            {" "}
            <strong>Inspection of Goods:</strong> The Buyer shall have to
            complete inspection of the Goods within 7 days from the date of
            intimation of request for inspection by the Seller failing which,
            the inspection shall be deemed to have been satisfactorily completed
            and the Despatch payment shall become immediately due and payable by
            the Buyer. If the Buyer fails to pay Seller, the Despatch Payment,
            the Buyer shall be liable to pay interest at an annual rate equal to
            18% per annum, accruing on a daily basis until payment is made.
          </p>

          <p>
            {" "}
            <strong> Despatch: </strong>After clearing the Despatch Payment, the
            Buyer shall give clearance to the Seller to despatch the Goods
            (“Despatch Clearance”). In case the Despatch Clearance is not given
            within 15 days from the date of inspection, the Buyer shall pay
            0.05% of the Purchase Price as warehousing charges for every day
            till the Despatch Clearance is not given. In case, the Buyer delays
            in giving the Despatch Clearance beyond 30 days from the date of
            inspection, the Buyer shall be deemed to have abandoned the Order
            and the Seller shall have the option to terminate the Order and
            claim the unpaid amount along with interest at an annual rate equal
            to 18% per annum, accruing on a daily basis until payment is made.
          </p>

          <p>
            {" "}
            <strong> Delivery and Acceptance:</strong> Seller will use
            commercially reasonable efforts to meet the quoted delivery dates
            and will have the right to deliver partial shipments of Goods. Buyer
            is deemed to have accepted the Goods upon delivery unless Buyer
            notifies Seller in writing of any loss, damage, shortage or other
            non-conformity within seven (7) days from the date of delivery. In
            the absence of receipt of such notice, Buyer will have no right to
            reject the Goods or Services. Buyer will have no right to suspend or
            delay delivery of Goods. If Buyer fails to accept delivery of Goods,
            Seller will have the right to store or arrange storage of the Goods
            at Buyer’s risk and expense. Buyer will promptly pay for the
            reasonable costs of storage and insurance of the Goods until
            delivery. Seller will not accept any returns of Goods without
            Seller’s prior written authorization.{" "}
          </p>

          <p>
            {" "}
            <strong> Risk and Title:</strong> The risk of loss or damage to the
            Goods will pass to Buyer when the Goods are despatched by the Seller
            from its premises. Title to the Goods will pass to the Buyer when
            Seller has received payment in full of the Purchase Price of the
            Goods. Until title has passed to Buyer, Buyer will hold the Goods on
            a fiduciary basis as Seller’s bailee. Buyer represents that it has
            adequate insurance to cover the risk of loss or damage to the Goods.
            Seller warrants that title to the Goods passes to Buyer upon
            Seller’s receipt of payment in full for such Good.{" "}
          </p>

          <p>
            {" "}
            <strong>Security Interest:</strong> Buyer grants Seller a security
            interest in the Goods in the amount of the unpaid balance of the
            Purchase Price until paid in full. Seller will have the right to
            exercise security interest in the Goods if the Buyer defaults in
            paying the Purchase Price in full and the Buyer shall sign any
            documentation that Seller deems necessary to protect Seller’s
            security interest upon request by Seller.
          </p>

          <p>
            {" "}
            <strong>Installation: </strong> The Buyer shall be responsible to
            provide all necessary support and facilities at the installation
            site for the installation of the Goods.{" "}
          </p>

          <p>
            {" "}
            <strong>Commissioning:</strong> The Seller shall intimate the date
            of readiness for trial run of the Goods to the Buyer at least 7 days
            in advance. The Buyer shall be responsible to arrange the grains for
            commissioning/ trial run of the Goods before the date of readiness.
            In case the Buyer delays in arranging grain for commissioning/ trial
            run more than 7 days from the date of readiness, the Goods shall be
            deemed to have been satisfactorily commissioned and the Final
            Payment shall become immediately due and payable.{" "}
          </p>

          <p>
            {" "}
            <strong> Warranties: </strong>Subject to warranty conditions under
            Clause 21, the Seller warrants the Goods to be free from defects in
            the material and workmanship for the respective period as specified
            below, beginning on the date of commissioning of the Goods: In the
            event of a product defect within the warranty period, the Buyer
            shall have to contact the Seller representative for instructions on
            obtaining warranty services. Only upon Seller’s verification of a
            defect under the warranty, the Seller will, at its sole option,
            either repair or replace, the defective product.{" "}
          </p>

          <p>
            {" "}
            <strong> Disclaimer: </strong> Except for the express warranties
            provided in terms, Seller makes no and hereby disclaim any other
            warranties, express, implied or statutory, with respect to the Goods
            or Services, including, without limitation, any implied warranty of
            title, merchantability, fitness for a particular purpose,
            non-infringement, or arising from course of performance, dealing,
            usage or trade. The Goods have been designed based on the
            information provided by the Buyer and for the specified Seismic Zone
            and wind speed conditions. Galvanised materials are subject to
            unsightly 'white rust' if moisture penetrates the wall and roof
            sheet bundles. To avoid this condition, separate sheets so that air
            can dry the panels. The Seller shall not be responsible for any
            consequences arising due to incomplete or incorrect information
            provided by the Buyer. Seller is not liable for: i) products
            installed or supplied by any person other than Seller; ii) products
            installed with structural components other than those supplied by
            the Seller; iii) product malfunctions, damage or failure resulting
            from misuse, abuse, negligence, alteration, accident, misapplication
            of specifications or lack of proper maintenance by the Buyer; iv)
            products used in conjunction with anything other than the mentioned
            free flowing materials.{" "}
          </p>

          <p>
            {" "}
            <strong> LIMITATION OF LIABILITY: </strong> NOTWITHSTANDING ANYTHING
            IN THESE TERMS TO THE CONTRARY, TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW, (A) IN NO EVENT WILL SELLER OR ITS SUPPLIERS BE
            LIABLE TO BUYER OR ANY THIRD PARTY FOR COSTS OF PROCUREMENT OF
            SUBSTITUTE GOODS OR SERVICES, OR FOR ANY SPECIAL, INDIRECT,
            EXEMPLARY, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES (INCLUDING,
            WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, BUSINESS
            INTERRUPTION OR ANY OTHER LOSS), AS A RESULT OF THE OFFER, SALE OR
            USE OF GOODS OR SERVICES, UNDER ANY THEORY OF LIABILITY, WHETHER
            BASED IN CONTRACT, NEGLIGENCE, TORT, WARRANTY OR OTHER WRONGFUL ACT
            OR OMISSION OF SELLER AND EVEN IF SELLER HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES AND (B) SELLER’S TOTAL LIABILITY FOR ALL
            CLAIMS WILL BE LIMITED TO DIRECT DAMAGES IN AN AMOUNT NOT TO EXCEED
            THE TOTAL PRICE PAID TO SELLER PURSUANT TO THE ORDER UNDER WHICH
            LIABILITY AROSE.{" "}
          </p>

          <p>
            {" "}
            <strong>Confidential Information: </strong> All technical and/or
            business information disclosed by a party which is either expressly
            identified as confidential or by its nature is implicitly
            confidential will be kept in strict confidence by the receiving
            party and not used by the receiving party, other than for the
            purposes of manufacture, sale, purchase or use of the Goods or to
            perform the party’s obligations hereunder.{" "}
          </p>

          <p>
            {" "}
            <strong> Indemnification: </strong> To the fullest extent permitted
            by applicable law, Buyer will defend, indemnify, and hold harmless
            Seller, its subsidiaries, affiliates, parents, partners, their
            successors and assigns, and each of their respective past and
            present directors, officers, employees and agents (collectively
            “Seller Indemnitees”) from and against any and all losses, damages,
            liabilities, demands, claims, actions, judgments, charges, court
            costs, and legal or other expenses, including, without limitation,
            reasonable attorneys’ fees (“Liabilities”), which Seller Indemnitees
            may sustain, incur, or become liable for in defending or
            compromising any suit, action, or other proceeding arising out of,
            related to, or in any way connected with Buyer’s purchase, sale, or
            use of the Goods or Services, including, but not limited to, Buyer’s
            misuse of such Goods or Services, environmental claims resulting
            from Buyer’s use of the Goods or Services, or any other acts or
            omissions, wilful misconduct or negligence, whether active or
            passive, on the part of Buyer.{" "}
          </p>

          <p>
            {" "}
            <strong> Force Majeure: </strong> Seller will not be liable for
            failures or delays caused by events or circumstances beyond its
            reasonable control.{" "}
          </p>

          <p>
            {" "}
            <strong> Assignment:</strong> Buyer will not have the right to
            assign or transfer Quotations or Orders, in whole or in part, except
            with Seller’s prior written consent. Seller has the right to assign
            its rights, or to delegate or subcontract its obligations or any
            portion thereof, to any affiliate or successor to its business or
            the assets to which these Terms relate. Seller does not owe warranty
            to any third party.{" "}
          </p>

          <p>
            {" "}
            <strong> Dispute Resolution & Jurisdiction:</strong> In case of any
            dispute in connection with the Order or these Terms, the same shall
            be referred to a mutually appointed sole arbitrator to be
            adjudicated in accordance with the Arbitration & Conciliation Act,
            1996. The venue of the arbitration shall be Delhi and the courts of
            Delhi shall have the exclusive jurisdiction.{" "}
          </p>

          <p>
            {" "}
            <strong> Miscellaneous:</strong> Time for payment is of the essence.
            Buyer acknowledges that it has not been induced to purchase any
            Goods or Services from Seller by any representation or warranty not
            expressly set forth herein. These Terms constitute the entire
            agreement of the parties a nd supersede all existing agreements and
            all other oral or written communications between the parties
            concerning the subject matter contained herein. None of the terms
            and conditions contained herein may be added to, modified,
            superseded, or otherwise altered except by a written document signed
            by an authorized representative of Seller. Any waiver or failure to
            enforce any provision herein on one occasion will not be deemed a
            waiver of any other provision or of such provision on any other
            occasion. Section headings contained herein are intended for
            convenience of reference only and will not affect the interpretation
            of any provision. If any provision of these Terms is held to be
            prohibited or unenforceable, the parties will promptly substitute
            for the invalid provision a valid and enforceable provision which
            most closely approximates the intent and economic effect of the
            invalid provision and the remaining provisions will continue in full
            force and effect.{" "}
          </p>

          <p>
            *Mentioned weight, thickness, grade of material, design standards
            etc in the techno-commercial offer are considered on approximation
            basis, which, may change as per final arrangement, structural
            calculation etc as per the requirement of the defined warranty
            period.
          </p>

          <p>
            ** Offered price is based on current day steel & other raw materials
            price of the mentioned grades, if the buyer delays the production or
            delivery clearnace beyond 60 days from the decided schedule, the
            seller can claim the difference in steel / other raw material prices
            in case of price escalation.
          </p>

          <p>
            *** If the water tank is installed although the buyer delays in
            arranging necessary piping connections, water etc for water tank
            leakage testing beyond 3 days from the date of completion of the
            water tank structure, the buyer needs to pay Rs. 500.00 per person
            of the installation team till the testing / commissioning is
            completed.
          </p>

          <p>
            **** The Buyer / End -User needs to fit appropriate instrument to
            absorb the vibration originates from the motors of the pumps, these
            water tanks are made of pre-fabricated steel and bolted at sight, if
            the vibration is not absorbed, it may cause structural damage and in
            that case, the seller shall not have any obligation / liability due
            the the damage.{" "}
          </p>
        </section>
      </Container>
      <Footer />
    </div>
  );
}

export default TermsPage;
