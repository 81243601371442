
import certicate1 from '../../assets/img/iso9001.png';
// import certicate2 from '../../assets/img/iso14001.png';
import certicate3 from '../../assets/img/iso_22000.png';
import certicate4 from '../../assets/img/fm_global.png';
import certicate5 from '../../assets/img/make_in_india.png';
import certicate6 from '../../assets/img/asme.png';


function Certification() {
    return (

        <section className="certificationSec">
            <div className="container">
              <div className="sectionTitle">
                <h6>CERTIFICATIONS</h6>
                <h1>A trustworthy organization to work with</h1>
                <p>We are a certified and trustworty organization certified by the valuable licenses & certifications</p>
              </div>

                <div className="certificates">
                  <img src={certicate1} alt='certicateImg'/>
                  <img src={certicate1} alt='certicateImg'/>
                  <img src={certicate3} alt='certicateImg'/>
                  <img src={certicate4} alt='certicateImg'/>
                  <img src={certicate5} alt='certicateImg'/>
                  <img src={certicate6} alt='certicateImg'/>
                </div>
              
            </div>
        </section>



    )
}

export default Certification