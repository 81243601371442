import { ActionTypes } from "../constants/action-types";

const initialstate = {
    error: {},
    success: false,
    query: {}

}



// this is the reducer function that will return the state of the application for all products
export const queryReducer = (state = initialstate, action: any) => {
    switch (action.type) {
        case ActionTypes.ADD_QUERY:
            return { ...state, success: true };
        case ActionTypes.QUERY_SUCCESS:
            return { ...state, success: true, query: action.payload.data };
        case ActionTypes.QUERY_FAIL:
            return { ...state, success: false, error: action.payload.error };
        default:
            return { ...state };
    }
}



