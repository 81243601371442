
import feature1 from '../../assets/img/one_stop_solution.png';
import feature2 from '../../assets/img/cost_benefit.png';
import feature3 from '../../assets/img/logistics_cost_benefit.png';
import feature4 from '../../assets/img/time_reduction.png';
import feature5 from '../../assets/img/tech_integration.png';
import feature6 from '../../assets/img/quality.png';
import feature7 from '../../assets/img/capacity_utlization.png';
import feature8 from '../../assets/img/ontime_payment_guarantee.png';
import feature9 from '../../assets/img/tech_integration.png';
import feature10 from '../../assets/img/raw_material.png';


function FeaturesSec() {
  return (

    <section className="certificationSec bg_light_grey" >
      <div className="container">
        <div className="customerFeature">

          <div className="sectionTitle">
            <h6>FEATURES</h6>
            <h1>For customers</h1>
            <p>Some of our top features to enforce you to work with us</p>
          </div>

          <div className="featureRow">
            <div className="featureDiv">
              <img src={feature1} alt='featureIcon' />
              <p>One Stop Solution for B2B  Steel Fabricated Products</p>
            </div>

            <div className="featureDiv">
              <img src={feature2} alt='featureIcon' />
              <p>Maximum Cost Benefit</p>
            </div>

            <div className="featureDiv">
              <img src={feature3} alt='featureIcon' />
              <p>Maximum Logistics Cost Benefit</p>
            </div>

            <div className="featureDiv">
              <img src={feature4} alt='featureIcon' />
              <p>Maximum Lead Time Reduction</p>
            </div>

            <div className="featureDiv">
              <img src={feature5} alt='featureIcon' />
              <p>Tech Integration for Easy  Ordering to Visibility</p>
            </div>
          </div>

        </div>

        <div className="vendorFeature">

          <div className="sectionTitle">
            <h6>FEATURES</h6>
            <h1>For MSME vendors</h1>
            <p>Some of our top features to enforce you to work with us</p>
          </div>

          <div className="featureRow">
            <div className="featureDiv">
              <img src={feature6} alt='featureIcon' />
              <p>Quality & Fabrication Process  Standaradization & Upgradation</p>
            </div>

            <div className="featureDiv">
              <img src={feature7} alt='featureIcon' />
              <p>Guaranteed Capacity Utilization</p>
            </div>

            <div className="featureDiv">
              <img src={feature8} alt='featureIcon' />
              <p>Ontime Payment Guarantee</p>
            </div>

            <div className="featureDiv">
              <img src={feature9} alt='featureIcon' />
              <p>Tech Integration with our  Platform, IOT, Machine, Vision  Camera, etc.</p>
            </div>

            <div className="featureDiv">
              <img src={feature10} alt='featureIcon' />
              <p>Raw Material Control</p>
            </div>
          </div>

        </div>

      </div>
    </section>



  )
}

export default FeaturesSec